/***********************************************
 * Animated SVG icons
 ***********************************************/
(function ($) {
    'use strict';

    // basic setup of elements
    var $elems = $('.dt-animated-svg').each(function () {
        var elem = this;

        if (this.tagName.toLowerCase() !== 'svg') {
            elem = $(this).find('svg').get(0);
        }

        var options = {
            duration:           75,
            start:              'manual',
            animTimingFunction: Vivus.EASE,
            onReady:            function () {
                $(elem).addClass('dt-animated-svg--ready');
            }
        };

        var vivus = new Vivus(elem, options, function (obj) {
            $(obj.el).addClass('dt-animated-svg--finished');
        });

        $(this).data('vivus-instance', vivus);
    });

    // batch appear for elements
    var elems_to_process = [], debounced_func = $.debounce(250, function () {
        var processing_elems = elems_to_process.slice(0);
        elems_to_process = [];

        $.each(processing_elems, function (index) {
            var $current_elem = $(this);

            setTimeout(function () {
                $current_elem.data('vivus-instance').play();
            }, index * 150);
        });
    });

    $elems.one('appear', function () {
        elems_to_process.push(this);
        debounced_func();
    });

    $(window).one('dt.preloader_done', function () {
        $elems.appear({ force_process: true });
    });
})(jQuery);