/***************************************************
 * Portfolio works slider
 ***************************************************/

(function ($) {
    'use strict';

    $('.dt-portfolio-wrap').on('initialized.owl.carousel changed.owl.carousel', function(e) {
      var slideIndex = e.page.index && e.page.index !== -1 ? e.page.index : 0;
      var slide = $(this).find('.dt-portfolio-block').eq(e.item.index);
      
      $('.dt-portfolio-current-count').text(String(slideIndex + 1).padStart(2, '0'));
      $('.dt-portfolio-total-count').text(String(e.item.count).padStart(2, '0'));

      $('.dt-portfolio-current-name').text(slide.find('.dt-portfolio-block-data.name').text());
      $('.dt-portfolio-current-tags').text(slide.find('.dt-portfolio-block-data.tags').text());
    });

    $('.dt-portfolio-wrap').owlCarousel({
      loop: true,
      center: true,
      margin: 40,
      responsive: {
        2000: {
          items: 3,
          stagePadding: 200,
        },
        1920: {
          items: 2,
          stagePadding: 200,
        },
        1550: {
          items: 3,
          stagePadding: 80,
        },
        1340: {
          items: 2,
          stagePadding: 0,
        },
        1000: {
          items: 2,
          stagePadding: 0,
          margin: 30,
        },
        768: {
          center:false,
          items: 2,
          margin: 30,
        },
        0: {
          center:false,
          items: 1,
          stagePadding: 0,
          margin: 0,
        },
      }
    });
})(jQuery);

/***************************************************
 * Mouse parallax for portfolio items
 ***************************************************/
(function ($) {
  $('.dt-portfolio-block').each(function() {
    var $container = $(this);

    function parallaxIt(e, target, movement) {
      var relX = e.pageX - $container.offset().left;
      var relY = e.pageY - $container.offset().top;

      TweenMax.to($container.find(target), 1, {
        x: (relX - $container.width() / 2) / $container.width() * movement,
        y: (relY - $container.height() / 2) / $container.height() * movement
      });
    }

    $container.mousemove(function(e) {
      if($('.owl-item').hasClass('active')) {
        parallaxIt(e, '.dt-portfolio-screen', -20);
      }
    });
  });
})(jQuery);