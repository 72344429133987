/***********************************************
 * Preloader
 ***********************************************/
(function ($) {
    'use strict';

    var $window = $(window);

    $window.on('load', function () {
        $('body').addClass('dt-page-loaded');
        setTimeout(function() { $window.trigger('dt.preloader_done') }, 1150);
        $('#dt-preloader').delay(2000).fadeOut();
    });
})(jQuery);