/***********************************************
 * Count up numbers
 ***********************************************/
(function ($) {
    'use strict';

    // basic setup of elements
    var $elems = $('.dt-fun-fact__number').each(function () {
        var $elem = $(this);

        var mc = new MinimalCounter('0'.repeat($elem.data('number').toString().length));
        $elem.html('').append(mc.el);
        $elem.data('minimal-counter', mc);
    });

    // batch appear for elements
    var elems_to_process = [], debounced_func = $.debounce(250, function () {
        var processing_elems = elems_to_process.slice(0);
        elems_to_process = [];

        $.each(processing_elems, function (index) {
            var $current_elem = $(this);

            setTimeout(function () {
                $current_elem.data('minimal-counter').update($current_elem.data('number'));
            }, index * 150);
        });
    });

    $elems.one('appear', function () {
        elems_to_process.push(this);
        debounced_func();
    });

    $(window).one('dt.preloader_done', function () {
        $elems.appear({ force_process: true });
    });

})(jQuery);