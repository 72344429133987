(function ($) {
    'use strict';

		$('.dt-testimonials-wrap').owlCarousel({
			center: false,
		    autoplay:true,
		    autoplayTimeout:6000,
		    autoplayHoverPause:true,
			loop: true,
			autoplaySpeed: 1000,
	      	responsive: {
	        1340: {
	          items: 3,
	          margin: 40,
	        },
	        768: {
	          items: 2,
	          margin: 30,
	        },
	        0: {
	          items: 1,
	          margin: 0,
	        },
	    }
    });
})(jQuery);