/***********************************************
 * About section onscroll animation
 ***********************************************/
(function ($) {
    'use strict';

    var calculateContainerWidth = function () {
        var $container = $('<div class="container"/>').appendTo($('body'));
        var containerWidth = $container.outerWidth();
        $container.remove();
        return containerWidth;
    };

    var $section = $('#dt-about');

    $(window).on('load scroll', function () {
        var wScrollBottom = $(window).scrollTop() + $(window).outerHeight();
        var visible_percentage = Math.min(Math.max((wScrollBottom - $section.offset().top) / $section.outerHeight(), 0), 1);
        var containerSideWidth = Math.ceil(($(window).outerWidth() - calculateContainerWidth()) / 2);

        $section.find('.dt-section-animated-side').css('transform', 'scaleX(' + (containerSideWidth * visible_percentage).toString() + ')');
    });

})(jQuery);