/***********************************************
 * Make clicks on mobile devices smoother
 ***********************************************/
jQuery(document).ready(function () {
    'use strict';

    if(typeof FastClick === 'function') {
        FastClick.attach(document.body);
    }
});


/***********************************************
 * Disable hover effects when page is scrolled
 ***********************************************/
(function () {
    'use strict';

    var body = document.body,
        timer;

    window.addEventListener('scroll', function () {
        clearTimeout(timer);
        if (!body.classList.contains('disable-hover')) {
            body.classList.add('disable-hover')
        }

        timer = setTimeout(function () {
            body.classList.remove('disable-hover')
        }, 100);
    }, false);
})();

(function () {
    'use strict';

    $('.dt-pricing-block.first .list').on( "click", function() {
        $('.dt-pricing-block.first .list ul').toggleClass('height');
    });

    $('.dt-pricing-block.second .list').on( "click", function() {
        $('.dt-pricing-block.second .list ul').toggleClass('height');
    });

    $('.dt-pricing-block.third .list').on( "click", function() {
        $('.dt-pricing-block.third .list ul').toggleClass('height');
    });

})();

(function () {
    'use strict';

    $(window).load(function(){
        $(".form-group input, .form-group textarea").on('change', function(){
            if($(this).val() != ""){
                $(this).addClass("has-content");
            }else{
                $(this).removeClass("has-content");
            }
        })
    });
    
})();

(function () {
    'use strict';

    $('.dt-file-input').change(function() {
        if ($(this).val() != '') $(this).prev().text('Attached files: ' + $(this)[0].files.length);
        else $(this).prev().text('Attach a file');
    });

})(jQuery);