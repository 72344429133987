'use strict';

/***********************************************
 * Proper animation delays for mobile menu
 ***********************************************/
(function ($) {

    var $burger_menu = $('#dt-mobile-nav-container');

    $burger_menu.find('.nav_menu > li').each(function () {
        var $this = $(this);
        $this.css('animation-delay', 300 + ($this.index() * 150) + 'ms');
    });

    // $burger_menu.find('.menu-item-has-children > a').on('click', function (e) {
    //     e.preventDefault();
    //     $('.menu-item-has-children > a').removeClass('sub-active').next('ul').slideUp(250);
    //
    //     if ($(this).next('ul').is(':visible') === false) {
    //         $(this).addClass('sub-active').next('ul').slideDown(250);
    //     }
    // });

    $burger_menu.find('a').on('click', function () {
        $('body').removeClass('dt-active-burger-menu');
    });

    $('#dt-mobile-nav-trigger').on('click', function () {
        $('body').toggleClass('dt-active-burger-menu');

        $burger_menu.find('.sub-active').each(function () {
            $(this).removeClass('sub-active').next('ul').hide();
        });

        return false;
    });

})(jQuery);

/***********************************************
 * Sticky Menu
 ***********************************************/
(function ($) {
    new Headroom(document.getElementById('dt-header'), { offset: 150 }).init();
})(jQuery);

/*(function ($) {

    $(window).on('load', function () {

        $('.dt-bullits a').mPageScroll2id({
            highlightSelector: '.dt-bullits a',
            scrollSpeed: 1500,
            scrollingEasing: 'easeInOutExpo',
            clickedClass: 'active-menu',
            highlightClass: 'active-menu'
        });
    });

})(jQuery);*/