/***********************************************
 * On-hover animation for intro dots
 ***********************************************/

(function ($) {
    var canvas = document.querySelector(".intro-bg"),
        ctx = canvas.getContext("2d"),
        particles = [],
        amount = 0,
        mouse = {x:-9999,y:-9999},
        dotPadding = 50,
        hoverRadius = 500;

    var ww = canvas.width = window.innerWidth;
    var wh = canvas.height = window.innerHeight;

    function Particle(x,y){
        this.x = x;
        this.y = y;
    }

    Particle.prototype.render = function() {
        var distance = Math.sqrt(Math.pow(this.x - mouse.x, 2) + Math.pow(this.y - mouse.y, 2));
        var distancePercentage = Math.min(Math.max(distance / hoverRadius, 0), 1);

        ctx.fillStyle = 'rgba(255, 255, 255, ' + (1 - distancePercentage) * 0.5 + ')';
        ctx.beginPath();
        ctx.arc(this.x, this.y, (1 - distancePercentage) * 3, 0, Math.PI * 2, false);
        ctx.fill();

        if (distance < hoverRadius) {

        }
    }

    function onMouseMove(e){
        mouse.x = e.pageX;
        mouse.y = e.pageY;
    }

    function onTouchMove(e){
        if(e.touches.length > 0) {
          mouse.x = e.touches[0].pageX;
          mouse.y = e.touches[0].pageY;
        }
    }

    function onTouchEnd(e){
      mouse.x = -9999;
      mouse.y = -9999;
    }

    function initScene(){
        ww = canvas.width = window.innerWidth;
        wh = canvas.height = window.innerHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        particles = [];
        var wCount = Math.ceil(ww / dotPadding);
        var hCount = Math.ceil(wh / dotPadding);
        for(var i = 1; i < wCount; i++){
            for(var j = 1; j < hCount; j++){
                particles.push(new Particle(ww / wCount * i, wh / hCount * j));
            }
        }
    
        amount = particles.length;
    }

    function render(a) {
        requestAnimationFrame(render);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (var i = 0; i < amount; i++) {
            particles[i].render();
        }
    };

    window.addEventListener("resize", initScene);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchmove", onTouchMove);
    window.addEventListener("touchend", onTouchEnd);
    initScene();
    requestAnimationFrame(render);
})(jQuery);