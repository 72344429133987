$(document).ready(function() {
	$("#contact_form").on('submit', function(e) {
		e.preventDefault();

		var form = $(this);
		var preloader = form.find('.preloader');
		var returnmessage = form.find('.returnmessage');

		preloader.show();

		$.post("assets/php/contacts.php", form.serialize(), function(data) {
			preloader.hide();
			returnmessage.html(data.message);
			if (data.error === false) {
				form.reset();
			}
		});
	});
});

$(document).ready(function() {
	$("#contact_form_audit").on('submit', function(e) {
		e.preventDefault();

		var form = $(this);
		var returnmessage = form.find('.returnmessage');

		$.post("assets/php/contacts_audit.php", form.serialize(), function(data) {
			returnmessage.html(data.message);
			if (data.error === false) {
				form.reset();
			}
		});
	});
});