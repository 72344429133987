/***********************************************
 * Load theme fonts via WebFontLoader
 ***********************************************/
'use strict';
(function () {
    if (typeof STUDIO_FONTS === 'undefined') {
        return;
    }
    WebFont.load({
        google: {families: STUDIO_FONTS}
    });
})();